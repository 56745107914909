export const environment = {
  production: true,
  // serverUrl: 'https://localhost:5001/api/',
  fileUrl: 'https://localhost:5001/',
  //serverUrl: 'https://fleetautomation-test.aws-shared.gks.vodafone.com/api/',
   serverUrl:'https://voisegfleetautomation.vodafone.com/api/',
 // serverUrl: 'https://fleetautomation-dev.aws-shared.gks.vodafone.com/api/',



};

