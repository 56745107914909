import { PageNotFoundComponent } from './_components';
import { LoginComponent } from './_components/_login-components';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, LoginGuard } from './_guards';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './_components/_home-components';
import { RouteHomeComponent } from './_components/route-home/route-home.component';

const routes: Routes = [
  { path: '', component: RouteHomeComponent, canActivate: [MsalGuard] },
  // {
  //   path: 'login', component: LoginComponent, canActivate: [LoginGuard]
  // },
  {
    path: 'home', loadChildren: () => import('./_components/_home-components/home.module').then(m => m.HomeModule)
  },
  { path: '**', component: PageNotFoundComponent }
];


const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }