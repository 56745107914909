import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CredentialService } from '../_services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private credentialService: CredentialService
  ) { }

  intercept(httpReq: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    let headers: any;
    let Login: string = "users/login";
    let token: string = "";
    debugger;
    console.log("URL: "+environment.serverUrl + Login);
    if (httpReq.method == "GET" && httpReq.url == environment.serverUrl + Login) {

      token = localStorage.getItem('Mfleet');
      localStorage.removeItem('Mfleet');
      localStorage.clear();
     // console.log(token);
      if (token !== null) {
        headers = httpReq.headers.set('Authorization', `bearer ${token}`);
      }
      // return next.handle(requestToForward);
    }
    else if (this.credentialService.getToken()) {
      headers = httpReq.headers.set('Authorization', `bearer ${this.credentialService.getToken()}`);
    }
    const newReq = httpReq.clone({ headers });
    return next.handle(newReq);
  }
}
