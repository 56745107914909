import { Component } from '@angular/core';
import { CredentialService } from 'src/app/_services';
import { BnNgIdleService } from 'bn-ng-idle';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private bnIdle: BnNgIdleService, public credentialService: CredentialService,private authService: MsalService) { // initiate it in your component constructor
    // this.bnIdle.startWatching(15).subscribe((res) => {
    //   if (res) {
    //     //debugger;
    //     //console.log("session expired");


    //     //this.GetUserRoleSSO();
    //     // this.GetUserRoleLocal()
    //     // this.loguser.email=this.LoggedInUserData.Email;
    //     // this.loguser.name=this.LoggedInUserData.name;
    //     // this.logoutServerSide(this.loguser);
    //      this.logout();
    //   }
    // })
    //this.logout();
  }
  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: 'https://voisegfleetautomation.vodafone.com/'
    });
  }
}