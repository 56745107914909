import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router, NavigationExtras } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CredentialService } from '../_services';

@Injectable({
  providedIn: 'root'
})

export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    public credentialService: CredentialService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        debugger;
        if(error.status==0 || (error.status==401 && error.error.message=="An item with the same key has already been added. Key: Content-Security-Policy")
                           || ((error.status==500 && error.error.message=="An item with the same key has already been added. Key: Content-Security-Policy")) )
        {
          this.credentialService.logout();

        }
        else if (error) {
          console.log(error)
          this.snackBar.open(error.error.message, '', {
            duration: 2000,
            panelClass: ['red-snackbar']
          });
        } 
        return throwError(error);
      })
    );
  }

}
