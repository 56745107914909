import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890.-";
        const lengthOfCode = 40;
        let request:string="requests"
        let URL:string= environment.serverUrl+request;
        debugger;

        if(req.method=="POST" && req.url== URL){
            debugger;
            let requestToForward = req;
            //let token = this.getCookie("XSRF-TOKEN") as string;
            let token =  this.makeRandom(lengthOfCode, possible);
            if (token !== null) {
                requestToForward = req.clone({  headers: req.headers.set('x-xsrf-token', token) });
            }
            return next.handle(requestToForward);
        }
        return next.handle(req);
    }


    makeRandom(lengthOfCode: number, possible: string) {
        let text = "";
        text += localStorage.getItem('token').slice(10,20);
        for (let i = 0; i < lengthOfCode; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        text += localStorage.getItem('token');
        text += localStorage.getItem('token').slice(30,20);


          return text;
      }

    // not used yet
    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
      }   


}




// import { Injectable } from "@angular/core";
// import { HttpInterceptor, HttpXsrfTokenExtractor } from "@angular/common/http";
// import { HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
// import { Observable } from "rxjs";


// @Injectable()
// export class XsrfInterceptor implements HttpInterceptor {
//     constructor(private xsrfTokenExtractor: HttpXsrfTokenExtractor) {
//     }

//     intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//         //debugger;
//         // load token  

//         if (req.url == "https://localhost:44303/api/DPAs") {

        

//         let xsrfToken = this.xsrfTokenExtractor.getToken();

//         if (xsrfToken != null) {
//             // create a copy of the request and  
//             // append the XSRF token to the headers list  
//             const authorizedRequest = req.clone({
//                 //withCredentials: true,
//                 headers: req.headers.set('X-XSRF-TOKEN', xsrfToken)
//             });

//             return next.handle(authorizedRequest);
//         } else {
//             return next.handle(req);
//         }
//      }
//      return next.handle(req);

//     }
    
// }