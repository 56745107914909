import { Component } from '@angular/core';
import { RepositoryService, CredentialService } from 'src/app/_services';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import jwtDecode from 'jwt-decode';
import { Role } from 'src/app/_enums';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  loginForm: UntypedFormGroup;

  constructor(private credentialService: CredentialService, private repository: RepositoryService,
    // tslint:disable-next-line: align
    private snackBar: MatSnackBar, private router: Router, private formBuilder: UntypedFormBuilder) {
    this.createForm();
    
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required]
    });
  }

  public errorHandling = (control: string, error: string) => {
    return this.loginForm.controls[control].hasError(error);
  }

  login() {
    this.repository.post('users/login', this.loginForm.value).subscribe(
      (res: any) => {
        localStorage.setItem('token', res.token);
        this.router.navigate(['/home/requests/create']);
      });
  }

}


