import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AngularMaterialModule } from './shared/material.module';
import { LowerCaseUrlSerializer } from './_helpers/lower-case-url-serializer';
import { LoginComponent } from './_components/_login-components';
import { AppComponent, PageNotFoundComponent } from './_components';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UrlSerializer } from '@angular/router';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { ErrorInterceptor, TokenInterceptor } from './interceptors';
import { XsrfInterceptor } from './interceptors/XserfInterceptor';
import { RouteHomeComponent } from './_components/route-home/route-home.component';
import * as FileSaver from 'file-saver';
import { FileService } from './_services/File.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoginComponent,
    RouteHomeComponent
  ],
  imports: [
    BrowserModule,
    MatCheckboxModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
      MsalModule.forRoot( new PublicClientApplication({ 
        auth: {
          clientId: '5f5a8bc9-6aef-4ea4-9b22-2db0d0dba6f2', // Application (client) ID from the app registration
          authority: 'https://login.microsoftonline.com/68283f3b-8487-4c86-adb3-a5228f18b893',  // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          redirectUri: 'https://voisegfleetautomation.vodafone.com/'// This is your redirect URI
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        }
      }), {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: ['openid']
        }
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/me', ['user.read']]
      ])
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XsrfInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
    , {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    MsalGuard
  ],
  bootstrap: [AppComponent
     , MsalRedirectComponent
  ]
})
export class AppModule { }