import { Token } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import * as CryptoJS from 'crypto-js';
import { RepositoryService } from 'src/app/_services';
@Component({
  selector: 'app-route-home',
  templateUrl: './route-home.component.html',
  styleUrls: ['./route-home.component.css']
})
export class RouteHomeComponent implements OnInit {

  constructor(private repository: RepositoryService,private router: Router) { 
    var result = Object.keys(localStorage);
   // console.log("Home");
  //  var HrManagerToken='eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI4ZmIwMjY5ZC1hY2RiLTRiY2EtOGNmYi1iZjRhMTBjZGU2NTYiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vNjAwZmM4NGQtYjQ1Zi00Y2M1LThlYTQtNjM2MTk4NzJlMTNmL3YyLjAiLCJpYXQiOjE2NjIxMjQ4MTQsIm5iZiI6MTY2MjEyNDgxNCwiZXhwIjoxNjYyMTI4NzE0LCJncm91cHMiOlsiQVBQLVZTU0UtRmxlZXRBdXRvbWF0aW9uU09DLUhSTWdyIl0sIm5hbWUiOiJGbGVldC50ZXN0MiIsIm5vbmNlIjoiYzVkNmJiYjYtOTNiYy00ZDNhLTk3ZDItNDJmNTRhNmNjMGJjIiwib2lkIjoiNjU5MmJjYmYtNDdkMS00NTQyLWEzMTUtNmU0YzRlZGFhYTVkIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiRmxlZXQudGVzdDJAVm9kYWZvbmUtaXRjLmNvbSIsInJoIjoiMC5BU0FBVGNnUFlGLTB4VXlPcEdOaG1ITGhQNTBtc0lfYnJNcExqUHVfU2hETjVsWWdBTlUuIiwic3ViIjoiNnpzTkc0UEFjdTFZcEotNUJIcmFUem9Zb1JBcGRpX0FtdXVpUlB5N2t0ayIsInRpZCI6IjYwMGZjODRkLWI0NWYtNGNjNS04ZWE0LTYzNjE5ODcyZTEzZiIsInV0aSI6ImR5eG1EZWdHbVVTbVp1R3U0OEZDQUEiLCJ2ZXIiOiIyLjAifQ.UfsiqaOb75n9rcNDejfEcxt48GYQ5R0tBlS0OR0NfDatoeP2C5-K6AvPQFfRv8z3mf3fhjPnqbkvcPvu7AvhL4EKHvv_T4j61mIFarCvm49OQnT24pvkHiP4pO04KnaXBllihl2zYjzHCZGnLwPFwG337gwHghXM-zbxrtMNqf5WlgU7XWgKqkxBVSMy5O6l37BNDvhgyLHfCl6ZwHuB_87F4LUQ4vJgL00jfO0Gk0uCr1tuDI4ORbBov77jcLiWanju9WXYuxf5u4ogjrbgaj9rT-lIg4dTR_tvlhEmyPzctrfShIDY1ZorNILT0tUUPizB1uHM__nQxj-IMSg2XA';
    //var FleetToken='eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI4ZmIwMjY5ZC1hY2RiLTRiY2EtOGNmYi1iZjRhMTBjZGU2NTYiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vNjAwZmM4NGQtYjQ1Zi00Y2M1LThlYTQtNjM2MTk4NzJlMTNmL3YyLjAiLCJpYXQiOjE2NjIxMjIyOTIsIm5iZiI6MTY2MjEyMjI5MiwiZXhwIjoxNjYyMTI2MTkyLCJncm91cHMiOlsiQVBQLVZTU0UtRmxlZXRBdXRvbWF0aW9uQWRtaW4iXSwibmFtZSI6IkZsZWV0LlRlc3QxIiwibm9uY2UiOiJmMWFmOTU4OC0wMzhmLTRkM2YtODE4Ny00NDk1NzA0MzVmNDUiLCJvaWQiOiJhMGI3MGNkMy04ODQ2LTQyNTItOTIxZi1jNzdjZThmOGZiOTciLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJGbGVldC5UZXN0MUBWb2RhZm9uZS1pdGMuY29tIiwicmgiOiIwLkFTQUFUY2dQWUYtMHhVeU9wR05obUhMaFA1MG1zSV9ick1wTGpQdV9TaERONWxZZ0FIOC4iLCJzdWIiOiJ3ZkZnUV9UTDFDTXZJdkktekhLUE1zcGVldXFSNXpvSDk2SmhmNXduZDBBIiwidGlkIjoiNjAwZmM4NGQtYjQ1Zi00Y2M1LThlYTQtNjM2MTk4NzJlMTNmIiwidXRpIjoidzlOaTF4SF8yVUd3aEF3dVdGcE5BQSIsInZlciI6IjIuMCJ9.QI-8Qex3NdIx-imRb6edydG6vfCiHi6VG1OMgcAqDAuz1UtWnOTxL8NFVphCPDP7xm0CP9CmPI1i2i4CVPIV0zZl3caeBFxuSB0WaQt5W5UdA6G772BAeNC0MPr8E1AyFrpyaA4RPE6VxsN1Lo0LG3c0bU580xyV3z4f7ndUQZGHFDbTGDKTopbtf6wW_Xq87DunV_-rMfaNr0uP_TblE9ob97Phv8l_CRiGwPwdKYLvYRKkXChw23N6zG-TxnG_saQcV7DN-vDC9j4NxRQ1_EMRSwDTAvpyR8fR2K6-1mQa7wt8vXSJZAP2zIiHWwcaQInmNRapHyjaDs3FpB6lBA';
   //  localStorage.setItem('Mfleet',HrManagerToken);
   //  this.login();
   debugger;
   
   //var hackedToken='eyJraWQiOiI1MTIxNzdjYi02N2M4LTRlZjYtYjUxZS1mYTFkZjFhZThlYmYiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp3ayI6eyJrdHkiOiJSU0EiLCJlIjoiQVFBQiIsImtpZCI6IjUxMjE3N2NiLTY3YzgtNGVmNi1iNTFlLWZhMWRmMWFlOGViZiIsIm4iOiIxZUFIN0N2NmhFRTRIblN4TEVTc3BTb3cyTEtaSlVIcGZaN002Z1lrRWpwMUVNQjJSaEhIV3BMVVNlQmNuRElSNExqWFRSbFpaZUxxd24tQ1BrcWh1ZVF2T0JtaXVnTEZCeTNmajBObTBOdXNiTE1mOXlDUmJ2OXZPNDVIMzBJYm9WSHZNZkY2WWF1akVyMk5ra3M4dDFoem1MMzhRaUxaYlVoZE1HUk0yLUZFc1RNc1ZkWVRmVzV1THJCdUlPbEJGVUh2SGRjVDNEelc5N19BZTA4bUZpMTY5VXRSU1Z1TFdIMUZQY19hclZTT3lwZnhLWVJueXlwZ05BY1kxVk4wWWRhZ3hCX0twT2hQbkk4ZnFNQmRVd2FjYm1YTTRYN3BZWXpYUW1hX0RPQ2NueGpqLVhXXzFSSk9jVl9WUlg1dFhtanJPUzkwM1BsdlhfNXA2VlVGTFEifX0.eyJhdWQiOiI4ZmIwMjY5ZC1hY2RiLTRiY2EtOGNmYi1iZjRhMTBjZGU2NTYiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vNjAwZmM4NGQtYjQ1Zi00Y2M1LThlYTQtNjM2MTk4NzJlMTNmL3YyLjAiLCJpYXQiOjE2NjMyNDM2ODcsIm5iZiI6MTY2MzI0MzY4NywiZXhwIjoxNjYzMjQ3NTg3LCJncm91cHMiOlsiQVBQLVZTU0UtRmxlZXRBdXRvbWF0aW9uQWRtaW4iXSwibmFtZSI6IkZsZWV0LlRlc3Q2Iiwibm9uY2UiOiIyMDk0YzZmYy02MDRjLTQwZjktODA5MS1lNzgzZmViMmYxMWIiLCJvaWQiOiJkYzg4MzgyZS1lMzZmLTRmNDQtYjE5MC05ZmM1OGRjMTI2NWUiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJGbGVldC5UZXN0NkBWb2RhZm9uZS1pdGMuY29tIiwicmgiOiIwLkFTQUFUY2dQWUYtMHhVeU9wR05obUhMaFA1MG1zSV9ick1wTGpQdV9TaERONWxZZ0FFdy4iLCJzdWIiOiJEZG5wMkUwV19PN0p5alphWGthUnlNeU5ZZ2ZLam4tVHZtQWJSSTJtbExBIiwidGlkIjoiNjAwZmM4NGQtYjQ1Zi00Y2M1LThlYTQtNjM2MTk4NzJlMTNmIiwidXRpIjoiOUZOQmprZnBsMDJ6TGFlaWNJOFBBQSIsInZlciI6IjIuMCJ9.AvPDkMqUW19YWw-R2d_4z53jXw-hd7e-p1VeGbc2-_LqDtqs0Z_3g0nXhTt28uamaACQJaV_0E3-PTcaFCb4qmN4s2yykL36GI8oFjDPLqhvNpu60hSiXBAMbVn2PscX47B1AflILqumJqwezwr_U4v9Vq7VxKN2OB5GcpkY3jZwJtE-PTR8dQi1VABrrs28iG3me59jrhbq7tWx_pb58LHg9BOtr9srVr7WnojZmCtmfBqEEWOWDGOPumxg-UR_b79o739HzVv3teOw1s5RHRsNX5pIcAAwcf55YNQpjp1yMH-3oxDmT280IJH33X_KyVGzSjkzj1XovtxLhXHZ2Q';
   var HrManagerToken='eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI4ZmIwMjY5ZC1hY2RiLTRiY2EtOGNmYi1iZjRhMTBjZGU2NTYiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vNjAwZmM4NGQtYjQ1Zi00Y2M1LThlYTQtNjM2MTk4NzJlMTNmL3YyLjAiLCJpYXQiOjE2NjU2NjI0MjgsIm5iZiI6MTY2NTY2MjQyOCwiZXhwIjoxNjY1NjY2MzI4LCJncm91cHMiOlsiQVBQLVZTU0UtRmxlZXRBdXRvbWF0aW9uU09DLUhSTWdyIl0sIm5hbWUiOiJGbGVldC50ZXN0MiIsIm5vbmNlIjoiODlhNWFhMjAtMWZlNy00ODQ0LTg0YTEtNThhMmU2NmI0YzliIiwib2lkIjoiNjU5MmJjYmYtNDdkMS00NTQyLWEzMTUtNmU0YzRlZGFhYTVkIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiRmxlZXQudGVzdDJAVm9kYWZvbmUtaXRjLmNvbSIsInJoIjoiMC5BU0FBVGNnUFlGLTB4VXlPcEdOaG1ITGhQNTBtc0lfYnJNcExqUHVfU2hETjVsWWdBTlUuIiwic3ViIjoiNnpzTkc0UEFjdTFZcEotNUJIcmFUem9Zb1JBcGRpX0FtdXVpUlB5N2t0ayIsInRpZCI6IjYwMGZjODRkLWI0NWYtNGNjNS04ZWE0LTYzNjE5ODcyZTEzZiIsInV0aSI6Ikhxekd6TXFrMVUyN0lMeHJLRUk2QUEiLCJ2ZXIiOiIyLjAifQ.RKlB7RPfBweMOzFo_fTJxFLDdkPZhEo6TQU7tGs-ztVsdQ3cteVIg1Dn5SeZVBt-mT5rQAYl2wE9KKilTteM64FvV4xTi3HuWUxDyNyursmp7CUO5AIgiGkLgip_XxiZZ3An3ZOLFLop2RV9CFEoTAcn5LtdDbHE4tJ2UM7ybEuk2P-5ZulDQVuCPvPEnQkmWrjhJHWGk21k-kDxZ5ZxOSsG_V0fIfUb8bt8Mt__8OounOI1q4lmbqLfVllEL3YXETKQ2xDmWmFTK9vE3Lqm5Jd6Db1iSi2DIONGpceYhrj3DykjoyNnbKCVtWLCVWE2LghEsT2q7oXT3zqdYz9WKQ';
  

   
    //var FleetToken='eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI4ZmIwMjY5ZC1hY2RiLTRiY2EtOGNmYi1iZjRhMTBjZGU2NTYiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vNjAwZmM4NGQtYjQ1Zi00Y2M1LThlYTQtNjM2MTk4NzJlMTNmL3YyLjAiLCJpYXQiOjE2NjIxMjIyOTIsIm5iZiI6MTY2MjEyMjI5MiwiZXhwIjoxNjYyMTI2MTkyLCJncm91cHMiOlsiQVBQLVZTU0UtRmxlZXRBdXRvbWF0aW9uQWRtaW4iXSwibmFtZSI6IkZsZWV0LlRlc3QxIiwibm9uY2UiOiJmMWFmOTU4OC0wMzhmLTRkM2YtODE4Ny00NDk1NzA0MzVmNDUiLCJvaWQiOiJhMGI3MGNkMy04ODQ2LTQyNTItOTIxZi1jNzdjZThmOGZiOTciLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJGbGVldC5UZXN0MUBWb2RhZm9uZS1pdGMuY29tIiwicmgiOiIwLkFTQUFUY2dQWUYtMHhVeU9wR05obUhMaFA1MG1zSV9ick1wTGpQdV9TaERONWxZZ0FIOC4iLCJzdWIiOiJ3ZkZnUV9UTDFDTXZJdkktekhLUE1zcGVldXFSNXpvSDk2SmhmNXduZDBBIiwidGlkIjoiNjAwZmM4NGQtYjQ1Zi00Y2M1LThlYTQtNjM2MTk4NzJlMTNmIiwidXRpIjoidzlOaTF4SF8yVUd3aEF3dVdGcE5BQSIsInZlciI6IjIuMCJ9.QI-8Qex3NdIx-imRb6edydG6vfCiHi6VG1OMgcAqDAuz1UtWnOTxL8NFVphCPDP7xm0CP9CmPI1i2i4CVPIV0zZl3caeBFxuSB0WaQt5W5UdA6G772BAeNC0MPr8E1AyFrpyaA4RPE6VxsN1Lo0LG3c0bU580xyV3z4f7ndUQZGHFDbTGDKTopbtf6wW_Xq87DunV_-rMfaNr0uP_TblE9ob97Phv8l_CRiGwPwdKYLvYRKkXChw23N6zG-TxnG_saQcV7DN-vDC9j4NxRQ1_EMRSwDTAvpyR8fR2K6-1mQa7wt8vXSJZAP2zIiHWwcaQInmNRapHyjaDs3FpB6lBA';
    // localStorage.setItem('Mfleet',hackedToken);
   //  this.login();
    result.forEach((element) => {
      if (element.toLowerCase().indexOf('idtoken') != -1) {
        let data = JSON.parse(localStorage.getItem(element));

       localStorage.setItem('Mfleet',data['secret']);

          this.login();
      } else {
      }
    });
  }
  login() {
    debugger;
    this.repository.get('users/login').subscribe(
      (res: any) => {
        localStorage.clear();
        localStorage.setItem('token', res.token);
        this.router.navigate(['/home/requests/create']);
      });
  }
  base64url(source: any) {
    let encodedSource = CryptoJS.enc.Base64.stringify(source);

    encodedSource = encodedSource.replace(/=+$/, '');

    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  }
  encodeToken(payload:any) {
    var header = {
      "alg": "HS256",
      "typ": "JWT"
    };

    var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    var encodedHeader = this.base64url(stringifiedHeader);

    var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
    var encodedData = this.base64url(stringifiedData);

    var token = encodedHeader + "." + encodedData;
    return token;
  }

  ngOnInit(): void {
  }

}