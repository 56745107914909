import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})

export class CredentialService {

  constructor(private router: Router,/*private authService: MsalService*/) {
  }

  getToken() {
    const token = localStorage.getItem('token');
    return token;
  }

  getUser() {
    const user = jwt_decode(localStorage.getItem('token'));
    return user;
  }

  isLoggedIn() {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      return false;
    }
  }

  isAuthorized(role: string) {
    const user = this.getUser();
    return user.role === role;
  }

  logout() {
    localStorage.removeItem('token');
    // this.authService.logoutRedirect({
    //   postLogoutRedirectUri: 'https://voisegfleetautomation.vodafone.com/'
    // });
  }

}
